import { useEffect, useState } from 'react'
import {
  Box,
  Collapse,
  CircularProgress,
  Grow,
  Grid,
  Typography,
  Fade,
  makeStyles,
  Theme,
  Slide,
  Button,
} from '@material-ui/core'
import { useDispatch } from 'react-redux'
import { useReduxSelector, useReduxDispatch } from 'hooks'
import { createCash, deleteBankCard, getBankCardLink, updateCash } from 'api'
import { propsAction } from 'store/props'
import { ApiAnswerStatus } from 'types'
import { Modal } from 'components'
import { withPlanner, WithPlannerProps } from 'components/hoc'
import { authAction } from 'store/auth'
import { str } from 'utils'

const useStyles = makeStyles(({ palette, spacing }: Theme) => ({
  root: {
    overflow: 'hidden',
  },

  item: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: spacing(1.2),
    fontWeight: 400,
    flexWrap: 'nowrap',
    boxSizing: 'border-box',
    cursor: 'pointer',
    transition: 'all 150ms ease-in-out',
    '&.active': { boxShadow: `inset 0 0 0 1px ${palette.primary.main}` },
    '&:hover': { boxShadow: `inset 0 0 0 2px ${palette.primary.main}` },

    '& a': {
      fontWeight: 400,
      fontSize: 16,
      textDecoration: 'none',
      color: palette.text.secondary,
      '&:hover': { textDecoration: 'underline', color: palette.error.main },
    },
  },
}))

export const transferCardModalName = 'TransferCardModal'

export const TransferCardModal: React.FC<WithPlannerProps> = withPlanner(({ planner }) => {
  const classes = useStyles()
  const dispatch = useReduxDispatch()
  const authDispatch = useDispatch()
  const { SUCCESS } = ApiAnswerStatus
  const DEBUG = process.env.REACT_APP_DEBUG === 'true'
  const { modal } = useReduxSelector((state) => state.props)
  const { show = false, params } = modal[transferCardModalName] || {}
  const { summa, owner_cash_id } = params || {}

  const [processing, setProcessing] = useState<boolean>(true)
  const [screen, setScreen] = useState<'add' | 'select'>('select')
  const [currentCard, setCurrentCard] = useState<{ id: number; bank_card_number: string }>()
  const [sessionLink, setSessionLink] = useState<string>()

  const { bankCardList } = useReduxSelector((state) => state.props)

  const handleClose = () => {
    dispatch(propsAction.modal({ modal: { [transferCardModalName]: { show: false } } }))
    authDispatch(authAction.auth())
    setScreen('select')
    setProcessing(true)
  }

  const iframeMessageHandler = (e: MessageEvent) => {
    if (e.origin !== process.env.REACT_APP_API_URL?.replace(/\/api$/, '')) return

    if (e.data === 'close_add_card_iframe') {
      if (DEBUG) console.log('EventMessage:', 'close_add_card_iframe')
      authDispatch(authAction.auth())
      setScreen('select')
      setProcessing(true)
      window.removeEventListener('message', iframeMessageHandler)
    }
  }

  const createSeesionLink = async () => {
    const { status, message, data } = await getBankCardLink()
    if (status === SUCCESS) {
      if (typeof data?.url_redirect !== 'undefined') {
        setSessionLink(data?.url_redirect)
      } else if (planner?.clearTimeouts()) {
        planner?.timeout(
          () =>
            dispatch(
              propsAction.alert({
                message,
                onClose: () => {
                  setScreen('select')
                  setProcessing(true)
                },
              })
            ),
          200
        )
      }
    } else if (planner?.clearTimeouts()) {
      planner?.timeout(
        () =>
          dispatch(
            propsAction.alert({
              message,
              onClose: () => {
                setScreen('select')
                setProcessing(true)
              },
            })
          ),
        200
      )
    }
  }

  const onLoadIframe = () => {
    setProcessing(false)
    window.addEventListener('message', iframeMessageHandler)
  }

  useEffect(() => {
    if (bankCardList && bankCardList?.length > 0) {
      setScreen('select')
      setCurrentCard(bankCardList[0])
    } else setScreen('add')
  }, [bankCardList])

  useEffect(() => {
    if (show && bankCardList && bankCardList?.length === 0) {
      createSeesionLink()
      setScreen('add')
    }
  }, [show])

  const cashCreate = async () => {
    if (currentCard) {
      const { message, status } = owner_cash_id
        ? await updateCash({ id: owner_cash_id, cash_type: 'bank_card', owner_bank_card_id: currentCard.id })
        : await createCash({ cash_type: 'bank_card', owner_bank_card_id: currentCard.id })
      if (status === SUCCESS) {
        authDispatch(authAction.auth())
        if (planner?.clearTimeouts()) {
          planner?.timeout(() => dispatch(propsAction.alert({ message })), 200)
        }
        handleClose()
      } else if (planner?.clearTimeouts()) {
        planner?.timeout(() => dispatch(propsAction.alert({ message })), 200)
      }
    }
  }

  const handleTransferAlert = () => {
    dispatch(
      propsAction.alert({
        title: 'Зачисление на карту',
        message: {
          info: [
            `Отлично!
           Ваши данные прошли проверку.
           Ваш кешбэк в размере <strong>${str.format.cost(
             summa
           )}</strong> будет зачислен на банковскую карту в течение <strong>10</strong>&nbsp;рабочих&nbsp;дней.`,
          ],
        },
        actions: [
          { title: 'Отменить', variant: 'outlined', color: 'primary' },
          { title: 'Подтвердить', onAction: cashCreate },
        ],
      })
    )
  }

  const deleteBankCardAction = async (id: number) => {
    const { message, status } = await deleteBankCard(id)
    if (status === SUCCESS) {
      authDispatch(authAction.auth())
      if (planner?.clearTimeouts()) {
        planner?.timeout(() => dispatch(propsAction.alert({ message })), 200)
      }
    } else if (planner?.clearTimeouts()) {
      planner?.timeout(() => dispatch(propsAction.alert({ message })), 200)
    }
  }

  const handleDeleteBankCard = ({ id, bank_card_number }: { id: number; bank_card_number: string }) => {
    dispatch(
      propsAction.alert({
        title: 'Удалить карту',
        message: {
          info: [`Вы точно хотите удалить карту <strong>${str.format.nowrap(bank_card_number)}</strong> ?`],
        },
        actions: [
          { title: 'Точно', onAction: () => deleteBankCardAction(id) },
          { title: 'Не хочу', variant: 'outlined', color: 'primary' },
        ],
      })
    )
  }

  return (
    <Modal
      open={show}
      maxWidth="sm"
      onCloseDialog={handleClose}
      name={transferCardModalName}
      title={screen === 'add' ? 'Добавить новую карту' : 'Зачисление на карту'}
    >
      <Box className={classes.root}>
        <Grid container alignItems="center" justifyContent="center">
          <Grid item xs={12}>
            <Collapse in={screen === 'select'} timeout={350} style={{ transformOrigin: '50% 100% 0' }} unmountOnExit>
              <Slide in={screen === 'select'} timeout={300} appear direction="right">
                <Box>
                  <Fade in={screen === 'select'} timeout={{ enter: 600, exit: 200 }} appear>
                    <Box maxWidth={430} mx="auto">
                      <Typography variant="body2">Добавьте или выберите карту для зачисления:</Typography>

                      <Box maxWidth={375} mx="auto" mt={3}>
                        <Grid container spacing={1}>
                          {bankCardList?.map((c, idx) => (
                            <Grid item xs={12} key={c.id}>
                              <Grow in timeout={{ enter: (idx + 1) * 400 }}>
                                <Box>
                                  <Box
                                    className={`${classes.item} ${currentCard?.id === c.id && 'active'}`}
                                    onClick={() => setCurrentCard(c)}
                                  >
                                    <Box pl={1 / 2}>{str.format.nowrap(c.bank_card_number)}</Box>
                                    <Box px={2}>
                                      <img
                                        alt=""
                                        width="100%"
                                        height="auto"
                                        style={{ display: 'block' }}
                                        src={`/imgs/logo/${c.bank_card_type.toLowerCase()}-logo.svg`}
                                      />
                                    </Box>
                                    <Box
                                      p={1 / 2}
                                      component="a"
                                      onClick={() => {
                                        setCurrentCard(c)
                                        handleDeleteBankCard(c)
                                      }}
                                    >
                                      Удалить
                                    </Box>
                                  </Box>
                                </Box>
                              </Grow>
                            </Grid>
                          ))}
                        </Grid>
                      </Box>

                      <Box mt={3} mx="auto" maxWidth={260}>
                        <Grid container spacing={2}>
                          {bankCardList && (
                            <Grid item xs={12}>
                              <Button fullWidth variant="contained" color="secondary" onClick={handleTransferAlert}>
                                Перевести
                              </Button>
                            </Grid>
                          )}

                          {bankCardList && bankCardList?.length < 3 && (
                            <Grid item xs={12}>
                              <Button
                                fullWidth
                                variant="outlined"
                                color="primary"
                                onClick={() => {
                                  createSeesionLink()
                                  setScreen('add')
                                }}
                              >
                                Добавить новую карту
                              </Button>
                            </Grid>
                          )}
                        </Grid>
                      </Box>
                    </Box>
                  </Fade>
                </Box>
              </Slide>
            </Collapse>
          </Grid>

          <Grid item xs={12}>
            <Collapse in={screen === 'add'} timeout={350} style={{ transformOrigin: '50% 0 0' }} unmountOnExit>
              <Slide in={screen === 'add'} timeout={300} appear direction="left">
                <Box>
                  <Fade in={screen === 'add'} timeout={{ enter: 600, exit: 200 }} appear>
                    <Box>
                      <Collapse in={processing}>
                        <Grow in={processing} style={{ transformOrigin: '50% 0 0 ' }} timeout={700}>
                          <Box my={2} textAlign="center">
                            <CircularProgress />
                          </Box>
                        </Grow>
                      </Collapse>

                      <Collapse in={!processing}>
                        <Grow in={!processing} style={{ transformOrigin: '50% 0 0 ' }} timeout={700}>
                          <Box>
                            <Box>
                              <iframe
                                id="transferCardModalIframe"
                                title={transferCardModalName}
                                onLoad={onLoadIframe}
                                src={sessionLink}
                                frameBorder="0"
                                height={500}
                                width="100%"
                              >
                                <Box textAlign="center">Пожалуйста, включте поддержку iframe в Вашем браузере.</Box>
                              </iframe>
                            </Box>
                          </Box>
                        </Grow>
                      </Collapse>

                      {bankCardList && bankCardList?.length > 0 && (
                        <Box mt={2} mx="auto" maxWidth={260}>
                          <Button
                            fullWidth
                            variant="contained"
                            onClick={() => {
                              setScreen('select')
                              setProcessing(true)
                            }}
                          >
                            Отмена
                          </Button>
                        </Box>
                      )}
                    </Box>
                  </Fade>
                </Box>
              </Slide>
            </Collapse>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  )
})
