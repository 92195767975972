import { useEffect, useState } from 'react'
import { Box, Grid, Typography, makeStyles, Theme, Button, Fade, Grow, Slide, Collapse } from '@material-ui/core'
import { useDispatch } from 'react-redux'
import { Modal } from 'components'
import { withPlanner, WithPlannerProps } from 'components/hoc'
import { createCash, updateCash, deletePhone } from 'api'
import { ApiAnswerStatus } from 'types'
import { useReduxSelector, useReduxDispatch } from 'hooks'
import { propsAction } from 'store/props'
import { authAction } from 'store/auth'
import { str } from 'utils'
import TransferPhoneForm from './transfer-phone-form'

const useStyles = makeStyles(({ palette, spacing }: Theme) => ({
  root: {
    overflow: 'hidden',
  },

  item: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: spacing(1.2),
    fontWeight: 400,
    boxSizing: 'border-box',
    cursor: 'pointer',
    transition: 'all 150ms ease-in-out',
    '&.active': { boxShadow: `inset 0 0 0 1px ${palette.primary.main}` },
    '&:hover': { boxShadow: `inset 0 0 0 2px ${palette.primary.main}` },

    '& a': {
      fontWeight: 400,
      fontSize: 16,
      textDecoration: 'none',
      color: palette.text.secondary,
      '&:hover': { textDecoration: 'underline', color: palette.error.main },
    },
  },
}))

export const transferPhoneModalName = 'TransferPhoneModal'

export const TransferPhoneModal: React.FC<WithPlannerProps> = withPlanner(({ planner }) => {
  const classes = useStyles()
  const dispatch = useReduxDispatch()
  const authDispatch = useDispatch()
  const { SUCCESS } = ApiAnswerStatus
  const { modal } = useReduxSelector((state) => state.props)
  const { show = false, params } = modal[transferPhoneModalName] || {}
  const { summa, owner_cash_id } = params || {}

  const { phoneList } = useReduxSelector((state) => state.props)

  const [screen, setScreen] = useState<'add' | 'select'>('select')
  const [currentPhone, setCurrentPhone] = useState<{ id: number; phone: string }>()

  const handleClose = () => {
    dispatch(propsAction.modal({ modal: { [transferPhoneModalName]: { show: false } } }))
    setScreen('select')
  }

  useEffect(() => {
    if (phoneList && phoneList?.length > 0) {
      setScreen('select')
      setCurrentPhone(phoneList[0])
    } else setScreen('add')
  }, [phoneList])

  const cashCreate = async () => {
    if (currentPhone) {
      const { message, status } = owner_cash_id
        ? await updateCash({ id: owner_cash_id, cash_type: 'phone', owner_phone_list_id: currentPhone.id })
        : await createCash({ cash_type: 'phone', owner_phone_list_id: currentPhone.id })
      if (status === SUCCESS) {
        authDispatch(authAction.auth())
        if (planner?.clearTimeouts()) {
          planner?.timeout(() => dispatch(propsAction.alert({ message })), 200)
        }
        handleClose()
      } else if (planner?.clearTimeouts()) {
        planner?.timeout(() => dispatch(propsAction.alert({ message })), 200)
      }
    }
  }

  const handleTransferAlert = () => {
    dispatch(
      propsAction.alert({
        title: 'Зачисление на телефон',
        message: {
          info: [
            `Ваш кешбэк в размере <strong>${str.format.cost(summa)}</strong> будет зачислен на номер <strong>${
              currentPhone && str.format.phone(currentPhone.phone)
            }</strong> в течение <strong>10</strong>&nbsp;рабочих дней.`,
          ],
        },
        actions: [
          { title: 'Отменить', variant: 'outlined', color: 'primary' },
          { title: 'Подтвердить', onAction: cashCreate },
        ],
      })
    )
  }

  const deletePhoneAction = async (id: number) => {
    const { message, status } = await deletePhone(id)
    if (status === SUCCESS) {
      authDispatch(authAction.auth())
      if (planner?.clearTimeouts()) {
        planner?.timeout(() => dispatch(propsAction.alert({ message })), 200)
      }
    } else if (planner?.clearTimeouts()) {
      planner?.timeout(() => dispatch(propsAction.alert({ message })), 200)
    }
  }

  const handleDeletePhone = ({ id, phone }: { id: number; phone: string }) => {
    dispatch(
      propsAction.alert({
        title: 'Удалить номер телефона',
        message: {
          info: [`Вы точно хотите удалить номер телефона <strong>${str.format.phone(phone)}</strong> ?`],
        },
        actions: [
          { title: 'Точно', onAction: () => deletePhoneAction(id) },
          { title: 'Не хочу', variant: 'outlined', color: 'primary' },
        ],
      })
    )
  }

  return (
    <Modal
      open={show}
      maxWidth="sm"
      onCloseDialog={handleClose}
      name={transferPhoneModalName}
      title={screen === 'add' ? 'Добавить новый номер' : 'Зачисление на телефон'}
    >
      <Box className={classes.root}>
        <Grid container alignItems="center" justifyContent="center">
          <Grid item xs={12}>
            <Collapse in={screen === 'select'} timeout={350} style={{ transformOrigin: '50% 100% 0' }} unmountOnExit>
              <Slide in={screen === 'select'} timeout={300} appear direction="right">
                <Box>
                  <Fade in={screen === 'select'} timeout={{ enter: 600, exit: 200 }} appear>
                    <Box maxWidth={430} mx="auto">
                      <Typography variant="body2">Укажите или выберите номер телефона:</Typography>

                      <Box maxWidth={375} mx="auto" mt={3}>
                        <Grid container spacing={1}>
                          {phoneList?.map((p, idx) => (
                            <Grid item xs={12} key={p.id}>
                              <Grow in timeout={{ enter: (idx + 1) * 400 }}>
                                <Box>
                                  <Box
                                    className={`${classes.item} ${currentPhone?.id === p.id && 'active'}`}
                                    onClick={() => setCurrentPhone(p)}
                                  >
                                    <Box pl={1 / 2}>{str.format.phone(p.phone)}</Box>
                                    <Box
                                      p={1 / 2}
                                      component="a"
                                      onClick={() => {
                                        setCurrentPhone(p)
                                        handleDeletePhone(p)
                                      }}
                                    >
                                      Удалить
                                    </Box>
                                  </Box>
                                </Box>
                              </Grow>
                            </Grid>
                          ))}
                        </Grid>

                        <Box mt={4} px={2}>
                          <Typography variant="subtitle1">
                            {str.normalize('Вы можете сформировать заказ кешбэка на сумму не более 15 000 рублей')}
                          </Typography>
                        </Box>
                      </Box>

                      <Box mt={3} mx="auto" maxWidth={260}>
                        <Grid container spacing={2}>
                          {phoneList && (
                            <Grid item xs={12}>
                              <Button fullWidth variant="contained" color="secondary" onClick={handleTransferAlert}>
                                Перевести
                              </Button>
                            </Grid>
                          )}

                          {phoneList && phoneList?.length < 3 && (
                            <Grid item xs={12}>
                              <Button fullWidth variant="outlined" color="primary" onClick={() => setScreen('add')}>
                                Добавить новый номер
                              </Button>
                            </Grid>
                          )}
                        </Grid>
                      </Box>
                    </Box>
                  </Fade>
                </Box>
              </Slide>
            </Collapse>
          </Grid>

          <Grid item xs={12}>
            <Collapse in={screen === 'add'} timeout={350} style={{ transformOrigin: '50% 0 0' }} unmountOnExit>
              <Slide in={screen === 'add'} timeout={300} appear direction="left">
                <Box>
                  <Fade in={screen === 'add'} timeout={{ enter: 600, exit: 200 }} appear>
                    <Box pt={4}>
                      <TransferPhoneForm onExit={() => setScreen('select')} />

                      {phoneList && phoneList?.length > 0 && (
                        <Box mt={2} mx="auto" maxWidth={260}>
                          <Button fullWidth variant="contained" onClick={() => setScreen('select')}>
                            Отмена
                          </Button>
                        </Box>
                      )}
                    </Box>
                  </Fade>
                </Box>
              </Slide>
            </Collapse>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  )
})
